// statusUtils.js

export const handlePaymentStatus = async (status, id) => {
  switch (status) {
    case "succeeded":
      window.location.href = `/${id}/status/complete`;
      await new Promise((resolve) => setTimeout(resolve, 10000));
      break;
    case "processing":
      window.location.href = `/${id}/status/processing`;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      break;
    case "requires_payment_method":

      break;
    case "requires_confirmation":

      break;
    case "failed":
      window.location.href = `/${id}/status/failed`;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      break;
    default:
      await new Promise((resolve) => setTimeout(resolve, 1000));
  }
};
