import type React from "react"
import { useState, memo, forwardRef } from "react"
import * as Dialog from "@radix-ui/react-dialog"
import { CreditCard, X } from "@phosphor-icons/react"
import { MODE } from "../../lib/constants/api"

// Types
interface CardData {
  title: string
  number: string
  inrNumber?: string
}

interface UPIData {
  success: string
  failure: string
}

interface CardDataStructure {
  success: CardData
  failure: CardData
  inr: CardData
  upi: UPIData
  common: {
    expiry: string
    cvv: string
  }
}

interface TabIndicatorProps {
  active: boolean
}

interface CardDetailsProps {
  mode: "success" | "failure" | "inr"
  isINR: boolean
}

interface UPIDetailsProps {
  data: UPIData
}

interface DialogContentProps {
  open: "success" | "failure" | "inr" | "upi"
  setOpen: (open: "success" | "failure" | "inr" | "upi") => void
  isINR: boolean
}

// Constants
const CARD_DATA: CardDataStructure = {
  success: {
    title: "Success",
    number: "4242 4242 4242 4242",
    inrNumber: "6074825972083818",
  },
  failure: {
    title: "Failure",
    number: "4000 0000 0000 0002",
    inrNumber: "4000 0000 0000 0127",
  },
  inr: {
    title: "INR",
    number: "4000 0000 0000 0259",
  },
  upi: {
    success: "success@upi",
    failure: "failure@upi",
  },
  common: {
    expiry: "06/32",
    cvv: "123",
  },
}

// Memoized sub-components
const TabIndicator: React.FC<TabIndicatorProps> = memo(({ active }) =>
  active ? <div className="w-full h-1 bg-[#c6fe1e] absolute -bottom-[3px]" /> : null,
)
TabIndicator.displayName = "TabIndicator"

const CardDetails: React.FC<CardDetailsProps> = memo(({ mode, isINR }) => {
  const data = CARD_DATA[mode]
  // Use INR specific number if available and isINR is true
  const cardNumber = isINR && data.inrNumber ? data.inrNumber : data.number

  return (
    <div
      className="w-[561px] h-[355px] max-w-[80vw] rounded-[30px] relative overflow-hidden border border-border-primary flex flex-col justify-between p-9"
      data-testid={`${mode}-card`}
    >
      <img
        src="/card.png"
        alt="Card Background"
        className="absolute inset-0 w-full h-full object-cover rounded-[30px] -z-10"
      />
      <div className="flex items-center justify-end">
        <span className="text-white  font-bold text-lg uppercase">{data.title}</span>
      </div>
      <div className="flex flex-col gap-4 w-full">
      <span className="text-white text-lg md:text-lg uppercase font-bold">John Doe</span>
        <span className="text-white text-lg md:text-2xl uppercase font-bold">{cardNumber}</span>
        <span className="h-[1px] bg-border-primary" />
        <div className="w-full flex items-center justify-between">
          <span className="text-white text-lg md:text-xl uppercase font-medium">
            EXPIRY : {CARD_DATA.common.expiry}
          </span>
          <span className="text-white text-lg md:text-xl uppercase font-medium">CVV : {CARD_DATA.common.cvv}</span>
        </div>
      </div>
    </div>
  )
})
CardDetails.displayName = "CardDetails"

const UPIDetails: React.FC<UPIDetailsProps> = memo(({ data }) => {
  return (
    <div
      className="w-[561px] max-w-[80vw] rounded-[30px] relative overflow-hidden border border-border-primary flex flex-col justify-between p-9 bg-background-secondary"
      data-testid="upi-details"
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-3 p-6 bg-background-primary rounded-xl border border-border-primary">
          <span className="text-text-primary font-bold text-lg flex items-center gap-2">
            <span className="w-2 h-2 rounded-full bg-[#22c55e]"/> Success UPI ID
          </span>
          <span className="text-text-secondary pl-4 text-lg font-mono" data-testid="success-upi">
            {data.success}
          </span>
        </div>
        <div className="flex flex-col gap-3 p-6 bg-background-primary rounded-xl border border-border-primary">
          <span className="text-text-primary font-bold text-lg flex items-center gap-2">
            <span className="w-2 h-2 rounded-full bg-[#ef4444]"/> Failure UPI ID
          </span>
          <span className="text-text-secondary pl-4 text-lg font-mono" data-testid="failure-upi">
            {data.failure}
          </span>
        </div>
      </div>
    </div>
  )
})
UPIDetails.displayName = "UPIDetails"

const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(({ open, setOpen, isINR }, ref) => (
  <Dialog.Content
    ref={ref}
    className="fixed top-1/2 left-1/2 max-w-[90vw] transform -translate-x-1/2 -translate-y-1/2 bg-background-primary border border-border-primary p-6 rounded-xl shadow-lg"
  >
    <Dialog.Title className="text-base font-display gap-2 font-medium text-text-primary flex flex-col">
      <div className="flex items-center w-full justify-between">
        <div className="rounded-full w-fit p-3 bg-background-secondary text-text-primary">
          <CreditCard className="w-7 h-7" />
        </div>
        <Dialog.Close className="text-text-primary text-sm cursor-pointer w-fit">
          <X />
        </Dialog.Close>
      </div>
      Test Payment Methods
    </Dialog.Title>
    <Dialog.Description className="mt-2 text-sm text-text-secondary">
      Use these details to simulate payment success and failures
    </Dialog.Description>
    <section className="flex flex-col mt-4 gap-4 items-center">
      <div className="flex my-2 mt-0 items-center font-display text-base border-b-2 border-border-primary w-fit gap-4">
        <span className="relative text-text-primary pb-1 cursor-pointer" onClick={() => setOpen("success")}>
          Payment Success
          <TabIndicator active={open === "success"} />
        </span>
        <span className="relative text-text-primary pb-1 cursor-pointer" onClick={() => setOpen("failure")}>
          Payment Failure
          <TabIndicator active={open === "failure"} />
        </span>
        {isINR && (
          <span className="relative text-text-primary pb-1 cursor-pointer" onClick={() => setOpen("upi")}>
            UPI Test ID
            <TabIndicator active={open === "upi"} />
          </span>
        )}
      </div>
      {open === "upi" ? <UPIDetails data={CARD_DATA.upi} /> : <CardDetails mode={open} isINR={isINR} />}
    </section>
  </Dialog.Content>
))
DialogContent.displayName = "DialogContent"

interface TestCardProps {
  isINR?: boolean
}

const TestCard: React.FC<TestCardProps> = ({ isINR = false }) => {
  const [open, setOpen] = useState<"success" | "failure" | "inr" | "upi">("success")
  if (!MODE) return null
  return (
    <section className="w-full mt-6 border flex-col md:flex-row border-border-primary rounded-xl p-3 md:p-6 flex items-center justify-between">
      <div className="flex items-center justify-start w-full md:w-fit gap-2">
        <div className="rounded-full p-3 border border-border-primary text-text-primary">
          <CreditCard className="w-7 h-7" />
        </div>
        <div className="flex flex-col text-sm max-w-[260px]">
          <span className="text-text-primary font-medium">Test Payment Methods</span>
          <span className="text-text-secondary leading-tight text-sm">
            Use these details to simulate payment success and failures
          </span>
        </div>
      </div>

      <Dialog.Root>
        <Dialog.Trigger className="md:w-fit w-full justify-end flex">
          <div className="font-semibold w font-display text-text-primary">View Test Methods</div>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-50" />
          <DialogContent open={open} setOpen={setOpen} isINR={isINR} />
        </Dialog.Portal>
      </Dialog.Root>
    </section>
  )
}

export default TestCard
