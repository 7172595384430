import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "../../lib/ThemeContext";
import { confetti } from "@tsparticles/confetti";
import { useTranslation } from "react-i18next";
import { API_BASE_URL } from "../../lib/constants/api";
import {Helmet} from "react-helmet";

const UnifiedStatusPage = () => {
  const { id, status } = useParams<Record<string, string | undefined>>(); 
  const { theme } = useTheme();
  const { t } = useTranslation();

  const [time, setTime] = useState(5); 

  useEffect(() => {
    if (status === "complete") {
      setTimeout(() => {
        confetti({
          particleCount: 600,
          spread: 100,
          origin: { y: 0.5 },
        });
      }, 300);
    }
  }, [status]);

  useEffect(() => {
    if (status !== "not-found" && status !== "error") {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 1) {
          clearInterval(intervalId);
          window.location.href = `${API_BASE_URL}/return/${id}`;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId); 
  }
  }, [id, status]);

  const renderContent = () => {
    switch (status) {
      case "failed":
        return (
          <>
            <img src="/failed_mark.svg" alt="failed mark" />
            <span className="font-display text-center font-semibold text-2xl text-text-primary">
              {t("FailedPage.title")}
            </span>
            <p className="font-body text-wrap text-text-secondary text-sm text-center">
              {t("FailedPage.description")}
            </p>
            <div className="text-button-clink-default flex flex-col text-sm font-medium text-center">
              <span>Redirecting in {time}s</span>
              <a href={`${API_BASE_URL}/return/${id}`} className="underline">
                {t("FailedPage.redirect_link_text")}
              </a>
            </div>
          </>
        );

      case "not-found":
        return (
          <>
            <img src="/failed_mark.svg" alt="failed mark" />
            <span className="font-display text-center font-semibold text-2xl text-text-primary">
              {t("NotFoundPage.title")}
            </span>
            <p className="font-body text-wrap text-text-secondary text-sm text-center">
              {t("NotFoundPage.description")}
            </p>
            
          </>
        );
      case "error":
        return (
          <>
            <img src="/failed_mark.svg" alt="failed mark" />
            <span className="font-display text-center font-semibold text-2xl text-text-primary">
              {t("ErrorPage.title")}
            </span>
            <p className="font-body text-wrap text-text-secondary text-sm text-center">
              {t("ErrorPage.description")}
            </p>
            
          </>
        );

      case "complete":
        return (
          <>
            <img src="/check_mark.svg" alt="success mark" />
            <span className="font-display font-semibold text-2xl text-text-primary">
              {t("CompletePage.title")}
            </span>
            <p className="font-body text-wrap text-text-secondary text-sm text-center">
              {t("CompletePage.description")}
            </p>
            <div className="text-button-clink-default flex flex-col  text-sm font-medium text-center">
              <span>Redirecting in {time}s</span>
              <a href={`${API_BASE_URL}/return/${id}`} className="underline">
                {t("CompletePage.redirect_link_text")}
              </a>
            </div>
          </>
        );

      case "processing":
      default:
        return (
          <>
            <img className="animate-spin" src="/loading_spinner.svg" alt="loading" />
            <span className="font-display text-center font-semibold text-2xl text-text-primary">
              {t("ProcessingPage.title")}
            </span>
            <p className="font-body text-wrap text-text-secondary text-sm text-center">
              {t("ProcessingPage.description")}
            </p>
            <div className="text-button-clink-default flex flex-col  text-sm font-medium text-center">
              <span>{t("ProcessingPage.redirect_message")}</span>
              <a href={`${API_BASE_URL}/return/${id}`} className="underline">
                {t("ProcessingPage.redirect_link_text")}
              </a>
            </div>
          </>
        );
    }
  };

  return (
    <main className="w-[100vw] font-body h-screen bg-background-primary lg:bg-background-secondary flex items-center justify-center">
      <Helmet>
        <title>Payment Status - Dodo Payment Checkout</title>
    </Helmet>
      <div className="bg-background-primary z-50 max-w-sm rounded-xl shadow-none lg:shadow-md border-none lg:border border-border-primary flex flex-col items-center pt-14 px-8 pb-6">
        <div className="flex flex-col items-center gap-6 mb-3">{renderContent()}</div>
        <div className="flex items-center mt-4 gap-1">
          <span className="text-text-secondary text-[13px] font-normal">
            {t("ProcessingPage.powered_by")}
          </span>
          <img
            alt="dodopayments logo"
            src={theme !== "dark" ? "/dodo_logo.svg" : "/dodo_logo_dark.svg"}
          />
        </div>
      </div>
    </main>
  );
};

export default UnifiedStatusPage;
